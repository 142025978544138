
import { Component, Vue } from 'vue-property-decorator'
import { KeepAlive } from '@/utils/decorators'
import OrderDetailDialog from './OrderDetailDialog.vue'
import { OrderDetail } from '@/types/workOrder.d'

@KeepAlive
@Component({
  name: 'MyOrder',
  components: {
    OrderDetailDialog
  }
})
export default class MyOrder extends Vue {
  private activeName ='0'
  private searchInfo = {
    projectId: '',
    orderType: '',
    orderTitle: '',
    orderStatus: '',
    orderUserName: '',
    startTime: '',
    endTime: ''
  }

  private total = 0
  private page = 1
  private size = 10
  private loading = false
  private tableData = []
  private typeList = []
  private dateArr = []
  private statusList = ['待反馈', '待审核', '已完成', '已关闭']

  private detailShow = false
  private handleType = '0' // 处理类型 4：查看 0：反馈 1：审核 2：转办 3：关闭
  private orderId = ''

  get projectList () {
    return this.$store.state.projectList
  }

  get userInfo () {
    return JSON.parse(sessionStorage.getItem('userInfo') as string)
  }

  created () {
    this.getData()
  }

  activated () {
    this.getData()
  }

  getTypeList () {
    this.searchInfo.orderType = ''
    this.$axios.get(this.$apis.workOrder.selectWorkOrderTypeByList, {
      projectId: this.searchInfo.projectId,
      orderTypeStatus: '1'
    }).then((res) => {
      this.typeList = res.list || []
    })
  }

  getData () {
    const infoList = [
      {
        orderUserId: this.userInfo.userId
      },
      {
        createUserId: this.userInfo.userId
      },
      {
        myOrder: this.userInfo.userId
      }
    ]
    this.loading = true
    this.$axios.get(this.$apis.workOrder.selectWorkOrderByPage, {
      ...this.searchInfo,
      ...infoList[Number(this.activeName)],
      page: this.page,
      size: this.size
    }).then((res) => {
      this.total = res.total || 0
      this.tableData = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  dateChange (date: any) {
    if (date) {
      this.searchInfo.startTime = date[0]
      this.searchInfo.endTime = date[1]
    } else {
      this.searchInfo.startTime = ''
      this.searchInfo.endTime = ''
    }
  }

  getState (state: string) {
    const arr = ['', '未开工', '在建', '已移交']
    return arr[Number(state)]
  }

  getStatusName (orderStatus: string) {
    return this.statusList[Number(orderStatus)]
  }

  onAdd () {
    this.$router.push({ name: 'myOrderAdd' })
  }

  onDetailShow (row: OrderDetail, type: string) {
    if (type) {
      this.handleType = type
    } else {
      this.handleType = row.orderStatus || ''
    }
    this.orderId = row.orderId || ''
    this.detailShow = true
  }

  closeDialog () {
    this.detailShow = false
    this.getData()
  }
}
